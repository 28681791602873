<template>
  <div :class="$style.wrap">
    <a-table :columns="columns" :rowKey="row => row.name" :data-source="list" />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class WarnErrorDetail extends Vue {
  @Prop({ type: Array, default: () => [] }) dataList;

  list = [];
  mounted() {
    this.list = this.dataList;
  }
  columns = [
    {
      customRender: (text, row, index) => index + 1,
      title: '序号',
      align: 'center',
      width: 80,
    },
    {
      title: '操作类型',
      dataIndex: 'type',
      width: 120,
    },
    {
      title: '时间',
      dataIndex: 'time',
      width: 160,
      customRender: txt => moment(txt)?.format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '内容',
      ellipsis: true,
      dataIndex: 'content',
    },
  ];
}
</script>
<style lang="less" module>
.wrap {
  :global {
    .ant-pagination-item-active a,
    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a {
      color: white;
    }
    .ant-pagination-prev:hover .ant-pagination-item-link {
      color: white;
    }
    .ant-pagination-next:hover .ant-pagination-item-link {
      color: white;
    }
  }
}
</style>
