import Vue from 'vue';
import MessageBox from './message-box.vue';
import store from '@/store';

export default function createMessageBox(modalProps) {
  return new Promise(resolve => {
    const handleClose = () => {
      vm.handleClose();
      resolve(true);
    };

    const elm = document.createElement('div');
    document.body.appendChild(elm);

    const vm = new Vue({
      el: elm,
      store,
      data() {
        return {
          visible: true,
          modalProps,
        };
      },
      methods: {
        handleClose() {
          this.visible = false;
          this.$destroy();
          this.$el.parentElement.removeChild(this.$el);
        },
      },
      render() {
        return (
          <MessageBox
            visible={this.visible}
            styleObj={modalProps.style}
            {...{
              on: {
                'update:visible': value => (this.visible = value),
                'close': handleClose,
              },
            }}
          />
        );
      },
    });
  });
}
