<template>
  <div :class="$style.modalBox" :title="detail.messageName">
    <div :class="$style.msgList">
      <div :class="$style.msgItem">
        <label :class="$style.label">{{ $t('hat.broadcast.content') }}：</label>
        <span :class="$style.value"
          ><p>
            {{ contentFormat(detail.content) }}
          </p></span
        >
      </div>
      <div :class="$style.msgItem">
        <label :class="$style.label"
          >{{ $t('generalView.table.time') }}：</label
        >
        <span :class="$style.value"
          ><p>{{ timeFormat(detail.time) }}</p></span
        >
      </div>
      <div :class="$style.msgItem">
        <label :class="$style.label"
          >{{ $t('electricBox.mg.projectAddress') }}：</label
        >
        <span :class="$style.value"
          ><p>
            {{ dataFormat(detail.projectLocation) }}
          </p></span
        >
      </div>
      <div :class="$style.msgItem">
        <label :class="$style.label"
          >{{ $t('electricBox.mg.projectLeader') }}：</label
        >
        <span :class="$style.value">
          <p>{{ dataFormat(detail.principalName) }}</p>
        </span>
      </div>
      <div :class="$style.msgItem">
        <label :class="$style.label">{{ $t('screen.phoneNumber') }}：</label>
        <span :class="$style.value"
          ><p>
            {{ dataFormat(detail.principalPhone) }}
          </p></span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';

@Component()
export default class ScreenPop extends Vue {
  @Prop({ type: Object, default: () => {} }) detail;

  timeFormat(time) {
    if (time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return time;
    }
  }

  /**
   * @name 消息没有项目名称优化
   */
  contentFormat(message) {
    if (message && message.startsWith(',')) {
      return `该项目${message}`;
    } else {
      return message;
    }
  }

  dataFormat(value) {
    if (!value) {
      return '暂无';
    }
    return value;
  }
}
</script>
<style lang="less" module>
.modalBox {
  .msgList {
    padding: 0.2rem;
    color: rgba(6, 211, 255, 1);
    width: 100%;
    overflow-y: auto;
    font-size: 0.16rem;
    .msgItem {
      overflow: hidden;
      display: flex;
      padding: 0.03rem 0;
      line-height: 0.3rem;
      font-size: 0.14rem;
      .label {
        white-space: nowrap;
      }
      .value {
        display: block;
        flex: 1;
        p {
          word-break: break-all;
        }
      }
    }
  }
}
</style>
