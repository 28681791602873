<template>
  <div class="message-box-dialog">
    <div class="screen-header__msgbox" :style="{ ...styleObj }" v-if="visible">
      <div class="screen-header__msg">
        <el-tabs v-model="messageTabIndex" @tab-click="handleTabClick">
          <el-tab-pane :name="EVENT_FAULT">
            <div slot="label" class="msg-tab">
              <span
                :class="{ un_read: false && noVisitorMode && faultCount }"
                >{{ $t('generalView.key.fault') }}</span
              >
            </div>
            <message-item
              ref="faultItemRef"
              :messageList="faultArray"
              @show-message-item="handleShowMessageItem"
            />
          </el-tab-pane>
          <el-tab-pane :name="EVENT_WARN">
            <div slot="label" class="msg-tab">
              <span :class="{ un_read: false && noVisitorMode && warnCount }">{{
                $t('generalView.key.warning')
              }}</span>
            </div>
            <message-item
              ref="warnItemRef"
              :messageList="warnArray"
              @show-message-item="handleShowMessageItem"
            />
          </el-tab-pane>
          <!-- <el-tab-pane :name="EVENT_INFOS">
            <div slot="label" class="msg-tab">
              <span :class="{ un_read: false && noVisitorMode && infosCount }"
                >信息</span
              >
            </div>
            <message-item
              ref="warnItemRef"
              :messageList="infosArray"
              @show-message-item="handleShowMessageItem"
            />
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <div class="message-box-mask" @click.stop.prevent="handleClose"></div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import MessageItem from './message-item.vue';
import {
  EVENT_FAULT,
  EVENT_WARN,
  EVENT_INFOS,
} from '@/views/iot/screen/store.js';
@Component({
  components: {
    MessageItem,
  },
})
export default class MessageBox extends Vue {
  @Prop({ type: Boolean, default: false }) visible;
  @Prop({ type: Object, default: () => {} }) styleObj;

  @Emit('close')
  @Emit('update:visible')
  handleClose() {
    return false;
  }
  get noVisitorMode() {
    return !window.location.href.includes('/iot/screen/projectShare');
  }
  created() {
    this.EVENT_FAULT = EVENT_FAULT;
    this.EVENT_WARN = EVENT_WARN;
    this.EVENT_INFOS = EVENT_INFOS;
  }

  messageTabIndex = EVENT_FAULT;
  handleTabClick(name) {
    if (name === this.EVENT_FAULT) {
      this.$refs.faultItemRef && this.$refs.faultItemRef.handleInitLoad();
    } else {
      this.$refs.warnItemRef && this.$refs.warnItemRef.handleInitLoad();
    }
  }

  get faultArray() {
    return this.$store.state.screen.faultArray;
  }
  get warnArray() {
    return this.$store.state.screen.warnArray;
  }
  get infosArray() {
    return this.$store.state.screen.infosArray;
  }

  get faultCount() {
    return this.$store.getters['screen/faultCount'];
  }
  get warnCount() {
    return this.$store.getters['screen/warnCount'];
  }
  get infosCount() {
    return this.$store.getters['screen/infosCount'];
  }
  handleShowMessageItem() {
    this.handleClose();
  }
}
</script>
<style lang="less" scoped>
.message-box-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 999;
  /deep/ .el-badge > sup {
    background-color: #f22020;
  }
}
.message-box-mask {
  position: absolute;
  z-index: 21;
  width: 100%;
  height: 100%;
}
.screen-header__msgbox {
  position: absolute;
  z-index: 22;
  right: 4.5rem;
  top: 0.95rem;
  width: 4.22rem;
  height: 3.8rem;
  background-color: #02051b;
}
.screen-header__msg {
  box-shadow: 0 0 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
  background-repeat: no-repeat;
  background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
  padding-bottom: 0.05rem;

  .msg-title {
    display: flex;

    width: 100%;
    height: 0.5rem;
  }
  ::v-deep {
    .el-badge__content {
      border-radius: 0.1rem;
      font-size: 0.12rem;
      height: 0.18rem;
      line-height: 0.18rem;
      padding: 0 0.06rem;
      border: none;
      color: #ffffff;
    }
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__nav-wrap::after {
      background-color: #02051b;
    }
    .el-tabs__nav {
      border-bottom: 0.02rem solid #02051b;
      display: flex;
      width: 100%;
      box-shadow: inset 0 0 0.4rem 0.02rem rgba(12, 181, 232, 0.4);
      // background-color: #06192f50;
    }
    .el-tabs__item {
      height: 0.5rem;
      flex: 1;
      padding: 0;
    }
    .el-tabs__active-bar {
      background-color: #05c8f4;
      bottom: -0.02rem;
      height: 0.01rem;
      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 0.02rem;
        width: 0.46rem;
        background-color: #02051b;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
    .el-badge__content {
      border-radius: 0.1rem;
      font-size: 0.12rem;
      height: 0.18rem;
      line-height: 0.18rem;
      padding: 0 0.06rem;
    }
    .msg-tab {
      height: 0.5rem;
      cursor: pointer;
      flex: 1;
      text-align: center;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 400;
      opacity: 0.5;
      line-height: 0.5rem;
      color: rgba(5, 200, 244, 1);
      .un_read {
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          width: 0.1rem;
          height: 0.1rem;
          box-sizing: border-box;
          border-radius: 50%;
          background-color: #f22020;
        }
      }
      .message {
        position: absolute;
        top: 0.04rem;

        color: #ffffff;
      }
    }
    .is-active .msg-tab {
      opacity: 1;
      position: relative;
    }
  }
}
</style>
