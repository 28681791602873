<template>
  <div :class="$style['msg-content']" @scroll="handleScroll">
    <div
      :class="$style.msgPlantom"
      :style="{ height: `${$size(contentHeight)}px` }"
    ></div>
    <no-data-chart v-if="messageList.length === 0" />
    <div :class="$style.msgBox" ref="content" v-else>
      <div
        :class="[$style['msg-list'], $style['redIcon']]"
        v-for="(item, index) in list"
        :key="'msg' + index"
        @click="hanldeMessageBox(item)"
      >
        <div :class="$style['content-title']">{{ item.type }}</div>
        <div :class="$style.content">{{ contentFormat(item.content) }}</div>
        <div :class="[$style['msg-date']]">
          {{ formatDate(item.time) }}
          <span :class="$style['msgdateSee']">{{ $t('camera.look') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import ScreenPop from '@/views/iot/screen/components/message/pop.vue';
import createModal from '@/views/iot/screen/components/message/modal.js';
import { TYPE_UN_READ } from '@/views/iot/screen/store.js';
@Component()
export default class MessageItem extends Vue {
  @Prop({ type: Array, default: () => [] }) messageList;

  /**
   * @name 消息没有项目名称优化
   */
  contentFormat(message) {
    if (message && message.startsWith(',')) {
      return `该项目${message}`;
    } else {
      return message;
    }
  }

  TYPE_UN_READ = TYPE_UN_READ;

  formatDate(val) {
    return val ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : val;
  }

  hanldeMessageBox(item) {
    this.$emit('show-message-item');
    if (
      !window.location.href.includes('buildingFloor') &&
      item.readStatus === this.TYPE_UN_READ
    ) {
      this.$store.dispatch('screen/haveRead', {
        eventType: item.eventType,
        id: item.id,
      });
    }
    const data = {
      content: item.content,
      projectLocation: item.address,
      principalName: item.principal,
      principalPhone: item.phone,
      title: item.type,
      ...item,
    };

    createModal(() => <ScreenPop detail={data} />, {
      width: `${this.$size(500)}px`,
      title: data.title,
    });
  }

  handleInitLoad() {
    this.$nextTick(() => {
      this.updateList();
    });
  }

  @Watch('messageList', { immediate: true })
  messageListChange() {
    this.$nextTick(() => {
      this.updateList();
    });
  }

  list = [];
  itemHeight = 110;
  get contentHeight() {
    let count = this.messageList.length - 3;
    if (count < 4) {
      count = this.messageList.length;
    }
    return count * this.$size(this.itemHeight);
  }
  updateList(scrollTop) {
    scrollTop = scrollTop || 0;
    let count = Math.ceil(this.$el.clientHeight / this.$size(this.itemHeight));
    const start = Math.floor(scrollTop / this.$size(this.itemHeight));
    const end = start + (count ? count : 3);
    this.list = this.messageList.slice(start, end);
    const transform = `translate3d(0, ${start *
      this.$size(this.itemHeight)}px, 0)`;
    if (this.$refs.content) {
      this.$refs.content.style.webkitTransform = transform;
      this.$refs.content.style.msTransform = transform;
      this.$refs.content.style.MozTransform = transform;
      this.$refs.content.style.transform = transform;
    }
  }
  handleScroll() {
    const scrollTop = this.$el.scrollTop;
    this.updateList(scrollTop);
  }
}
</script>
<style lang="less" module>
.msg-content {
  position: relative;
  overflow-y: scroll;
  height: 3.3rem;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .msgPlantom {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .msgBox {
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
  }

  .msg-list {
    position: relative;
    height: 1.1rem;
    border-bottom: 0.01rem solid rgba(5, 200, 244, 0.2);
    padding: 0.08rem 0.48rem;
    box-sizing: border-box;
    cursor: pointer;
    .content-title {
      height: 0.24rem;
      font-size: 0.14rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 0.24rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 0.1rem;
    }
    .content {
      font-size: 0.14rem;
      line-height: 0.18rem;
      height: 0.38rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
      word-wrap: break-word;
      color: #ffffff;
    }
    .msg-date {
      color: #9e9e9e;
      opacity: 0.5;
      font-size: 0.14rem;
      padding-top: 0.05rem;
      &.red {
        opacity: 1;
      }
      &.white {
        opacity: 0.5;
      }
      .msgdateSee {
        cursor: pointer;
        float: right;
        font-size: 0.14rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #05c8f4;
        opacity: 1;
      }
    }
  }
  .redIcon::before {
    content: '';
    width: 0.14rem;
    height: 0.14rem;
    background: rgba(212, 17, 17, 1);
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 0.08rem 0.05rem rgba(212, 17, 17, 0.4);
    left: 0.2rem;
    margin-top: 0.07rem;
  }
  .whiteIcon::before {
    content: '';
    width: 0.14rem;
    height: 0.14rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 0.08rem 0.05rem rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    position: absolute;
    left: 0.2rem;
    margin-top: 0.07rem;
  }
}
</style>
